@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.tooltip {
  position: relative;
  overflow: visible;
}
.tooltip.is-tooltip-active:not(.is-loading)::after,
.tooltip:hover:not(.is-loading)::after {
  position: absolute;
  z-index: 99999;
  display: inline-block;
  pointer-events: none;
}
.tooltip.is-tooltip-active::before,
.tooltip:hover::before {
  position: absolute;
  z-index: 99999;
  display: inline-block;
  pointer-events: none;
}
.tooltip.is-tooltip-active:not(.is-loading)::after,
.tooltip:hover:not(.is-loading)::after {
  border-style: solid;
  border-width: 0.5rem;
  content: "";
}
.tooltip.is-tooltip-active::before,
.tooltip:hover::before {
  max-width: 24rem;
  padding: 0.4rem 0.8rem;
  overflow: hidden;
  color: #fff;
  font-size: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: rgba(74, 74, 74, 0.9);
  border-radius: 4px;
  opacity: 0;
  content: attr(data-tooltip);
}
.tooltip.is-tooltip-active:not(.is-loading)::after,
.tooltip:focus:not(.is-loading)::after,
.tooltip:hover:not(.is-loading)::after {
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  opacity: 1;
}
.tooltip.is-tooltip-active::before,
.tooltip:focus::before,
.tooltip:hover::before {
  opacity: 1;
}
.tooltip::before {
  top: auto;
  right: auto;
  bottom: 100%;
  left: 50%;
}
.tooltip.is-tooltip-active:not(.is-loading)::after,
.tooltip:focus:not(.is-loading)::after,
.tooltip:hover:not(.is-loading)::after {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent;
}
.tooltip.is-tooltip-active::before,
.tooltip:focus::before,
.tooltip:hover::before {
  -webkit-transform: translate(-50%, -0.5rem);
  transform: translate(-50%, -0.5rem);
}
.tooltip.is-tooltip-right::before {
  top: auto;
  right: auto;
  bottom: 50%;
  left: 100%;
  -webkit-transform: translate(-1rem, 50%);
  transform: translate(-1rem, 50%);
}
.tooltip.is-tooltip-right.is-tooltip-active:not(.is-loading)::after,
.tooltip.is-tooltip-right:focus:not(.is-loading)::after,
.tooltip.is-tooltip-right:hover:not(.is-loading)::after {
  top: 50%;
  right: auto;
  left: 100%;
  border-color: transparent rgba(74, 74, 74, 0.9) transparent transparent;
}
.tooltip.is-tooltip-right.is-tooltip-active::before,
.tooltip.is-tooltip-right:focus::before,
.tooltip.is-tooltip-right:hover::before {
  -webkit-transform: translate(0.5rem, 50%);
  transform: translate(0.5rem, 50%);
}
.tooltip.is-tooltip-bottom::before {
  top: 100%;
  right: auto;
  bottom: auto;
  left: 50%;
  -webkit-transform: translate(-50%, -1rem);
  transform: translate(-50%, -1rem);
}
.tooltip.is-tooltip-bottom.is-tooltip-active:not(.is-loading)::after,
.tooltip.is-tooltip-bottom:focus:not(.is-loading)::after,
.tooltip.is-tooltip-bottom:hover:not(.is-loading)::after {
  top: 100%;
  right: auto;
  bottom: auto;
  left: 50%;
  border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent;
}
.tooltip.is-tooltip-bottom.is-tooltip-active::before,
.tooltip.is-tooltip-bottom:focus::before,
.tooltip.is-tooltip-bottom:hover::before {
  -webkit-transform: translate(-50%, 0.5rem);
  transform: translate(-50%, 0.5rem);
}
.tooltip.is-tooltip-left::before {
  top: auto;
  right: 100%;
  bottom: 50%;
  left: auto;
  -webkit-transform: translate(1rem, 50%);
  transform: translate(1rem, 50%);
}
.tooltip.is-tooltip-left.is-tooltip-active:not(.is-loading)::after,
.tooltip.is-tooltip-left:focus:not(.is-loading)::after,
.tooltip.is-tooltip-left:hover:not(.is-loading)::after {
  top: 50%;
  right: calc(100% - 0.5rem);
  bottom: auto;
  left: auto;
  border-color: transparent transparent transparent rgba(74, 74, 74, 0.9);
}
.tooltip.is-tooltip-left.is-tooltip-active::before,
.tooltip.is-tooltip-left:focus::before,
.tooltip.is-tooltip-left:hover::before {
  -webkit-transform: translate(-0.5rem, 50%);
  transform: translate(-0.5rem, 50%);
}
.tooltip.is-tooltip-multiline::before {
  min-width: 24rem;
  white-space: normal;
  text-overflow: clip;
  word-break: keep-all;
}
.tooltip.is-tooltip-white:not(.is-loading)::after {
  border-color: rgba(255, 255, 255, 0.9) transparent transparent transparent;
}
.tooltip.is-tooltip-white.is-tooltip-right:not(.is-loading)::after {
  border-color: transparent rgba(255, 255, 255, 0.9) transparent transparent;
}
.tooltip.is-tooltip-white.is-tooltip-bottom:not(.is-loading)::after {
  border-color: transparent transparent rgba(255, 255, 255, 0.9) transparent;
}
.tooltip.is-tooltip-white.is-tooltip-left:not(.is-loading)::after {
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.9);
}
.tooltip.is-tooltip-white::before {
  color: #0a0a0a;
  background: rgba(255, 255, 255, 0.9);
}
.tooltip.is-tooltip-black:not(.is-loading)::after {
  border-color: rgba(10, 10, 10, 0.9) transparent transparent transparent;
}
.tooltip.is-tooltip-black.is-tooltip-right:not(.is-loading)::after {
  border-color: transparent rgba(10, 10, 10, 0.9) transparent transparent;
}
.tooltip.is-tooltip-black.is-tooltip-bottom:not(.is-loading)::after {
  border-color: transparent transparent rgba(10, 10, 10, 0.9) transparent;
}
.tooltip.is-tooltip-black.is-tooltip-left:not(.is-loading)::after {
  border-color: transparent transparent transparent rgba(10, 10, 10, 0.9);
}
.tooltip.is-tooltip-black::before {
  color: #fff;
  background: rgba(10, 10, 10, 0.9);
}
.tooltip.is-tooltip-light:not(.is-loading)::after {
  border-color: rgba(245, 245, 245, 0.9) transparent transparent transparent;
}
.tooltip.is-tooltip-light.is-tooltip-right:not(.is-loading)::after {
  border-color: transparent rgba(245, 245, 245, 0.9) transparent transparent;
}
.tooltip.is-tooltip-light.is-tooltip-bottom:not(.is-loading)::after {
  border-color: transparent transparent rgba(245, 245, 245, 0.9) transparent;
}
.tooltip.is-tooltip-light.is-tooltip-left:not(.is-loading)::after {
  border-color: transparent transparent transparent rgba(245, 245, 245, 0.9);
}
.tooltip.is-tooltip-light::before {
  color: #363636;
  background: rgba(245, 245, 245, 0.9);
}
.tooltip.is-tooltip-dark:not(.is-loading)::after {
  border-color: rgba(54, 54, 54, 0.9) transparent transparent transparent;
}
.tooltip.is-tooltip-dark.is-tooltip-right:not(.is-loading)::after {
  border-color: transparent rgba(54, 54, 54, 0.9) transparent transparent;
}
.tooltip.is-tooltip-dark.is-tooltip-bottom:not(.is-loading)::after {
  border-color: transparent transparent rgba(54, 54, 54, 0.9) transparent;
}
.tooltip.is-tooltip-dark.is-tooltip-left:not(.is-loading)::after {
  border-color: transparent transparent transparent rgba(54, 54, 54, 0.9);
}
.tooltip.is-tooltip-dark::before {
  color: #f5f5f5;
  background: rgba(54, 54, 54, 0.9);
}
.tooltip.is-tooltip-primary:not(.is-loading)::after {
  border-color: rgba(0, 209, 178, 0.9) transparent transparent transparent;
}
.tooltip.is-tooltip-primary.is-tooltip-right:not(.is-loading)::after {
  border-color: transparent rgba(0, 209, 178, 0.9) transparent transparent;
}
.tooltip.is-tooltip-primary.is-tooltip-bottom:not(.is-loading)::after {
  border-color: transparent transparent rgba(0, 209, 178, 0.9) transparent;
}
.tooltip.is-tooltip-primary.is-tooltip-left:not(.is-loading)::after {
  border-color: transparent transparent transparent rgba(0, 209, 178, 0.9);
}
.tooltip.is-tooltip-primary::before {
  color: #fff;
  background: rgba(0, 209, 178, 0.9);
}
.tooltip.is-tooltip-link:not(.is-loading)::after {
  border-color: rgba(50, 115, 220, 0.9) transparent transparent transparent;
}
.tooltip.is-tooltip-link.is-tooltip-right:not(.is-loading)::after {
  border-color: transparent rgba(50, 115, 220, 0.9) transparent transparent;
}
.tooltip.is-tooltip-link.is-tooltip-bottom:not(.is-loading)::after {
  border-color: transparent transparent rgba(50, 115, 220, 0.9) transparent;
}
.tooltip.is-tooltip-link.is-tooltip-left:not(.is-loading)::after {
  border-color: transparent transparent transparent rgba(50, 115, 220, 0.9);
}
.tooltip.is-tooltip-link::before {
  color: #fff;
  background: rgba(50, 115, 220, 0.9);
}
.tooltip.is-tooltip-info:not(.is-loading)::after {
  border-color: rgba(32, 156, 238, 0.9) transparent transparent transparent;
}
.tooltip.is-tooltip-info.is-tooltip-right:not(.is-loading)::after {
  border-color: transparent rgba(32, 156, 238, 0.9) transparent transparent;
}
.tooltip.is-tooltip-info.is-tooltip-bottom:not(.is-loading)::after {
  border-color: transparent transparent rgba(32, 156, 238, 0.9) transparent;
}
.tooltip.is-tooltip-info.is-tooltip-left:not(.is-loading)::after {
  border-color: transparent transparent transparent rgba(32, 156, 238, 0.9);
}
.tooltip.is-tooltip-info::before {
  color: #fff;
  background: rgba(32, 156, 238, 0.9);
}
.tooltip.is-tooltip-success:not(.is-loading)::after {
  border-color: rgba(35, 209, 96, 0.9) transparent transparent transparent;
}
.tooltip.is-tooltip-success.is-tooltip-right:not(.is-loading)::after {
  border-color: transparent rgba(35, 209, 96, 0.9) transparent transparent;
}
.tooltip.is-tooltip-success.is-tooltip-bottom:not(.is-loading)::after {
  border-color: transparent transparent rgba(35, 209, 96, 0.9) transparent;
}
.tooltip.is-tooltip-success.is-tooltip-left:not(.is-loading)::after {
  border-color: transparent transparent transparent rgba(35, 209, 96, 0.9);
}
.tooltip.is-tooltip-success::before {
  color: #fff;
  background: rgba(35, 209, 96, 0.9);
}
.tooltip.is-tooltip-warning:not(.is-loading)::after {
  border-color: rgba(255, 221, 87, 0.9) transparent transparent transparent;
}
.tooltip.is-tooltip-warning.is-tooltip-right:not(.is-loading)::after {
  border-color: transparent rgba(255, 221, 87, 0.9) transparent transparent;
}
.tooltip.is-tooltip-warning.is-tooltip-bottom:not(.is-loading)::after {
  border-color: transparent transparent rgba(255, 221, 87, 0.9) transparent;
}
.tooltip.is-tooltip-warning.is-tooltip-left:not(.is-loading)::after {
  border-color: transparent transparent transparent rgba(255, 221, 87, 0.9);
}
.tooltip.is-tooltip-warning::before {
  color: rgba(0, 0, 0, 0.7);
  background: rgba(255, 221, 87, 0.9);
}
.tooltip.is-tooltip-danger:not(.is-loading)::after {
  border-color: rgba(255, 56, 96, 0.9) transparent transparent transparent;
}
.tooltip.is-tooltip-danger.is-tooltip-right:not(.is-loading)::after {
  border-color: transparent rgba(255, 56, 96, 0.9) transparent transparent;
}
.tooltip.is-tooltip-danger.is-tooltip-bottom:not(.is-loading)::after {
  border-color: transparent transparent rgba(255, 56, 96, 0.9) transparent;
}
.tooltip.is-tooltip-danger.is-tooltip-left:not(.is-loading)::after {
  border-color: transparent transparent transparent rgba(255, 56, 96, 0.9);
}
.tooltip.is-tooltip-danger::before {
  color: #fff;
  background: rgba(255, 56, 96, 0.9);
}
@media screen and (max-width: 768px) {
  .is-tooltip-top-mobile::before {
    top: auto !important;
    right: auto !important;
    bottom: 100% !important;
    left: 50% !important;
  }
  .is-tooltip-top-mobile.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-top-mobile:focus:not(.is-loading)::after,
  .is-tooltip-top-mobile:hover:not(.is-loading)::after {
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent !important;
  }
  .is-tooltip-top-mobile.is-tooltip-active::before,
  .is-tooltip-top-mobile:focus::before,
  .is-tooltip-top-mobile:hover::before {
    -webkit-transform: translate(-50%, -0.5rem) !important;
    transform: translate(-50%, -0.5rem) !important;
  }
}
@media screen and (min-width: 769px), print {
  .is-tooltip-top-tablet::before {
    top: auto !important;
    right: auto !important;
    bottom: 100% !important;
    left: 50% !important;
  }
  .is-tooltip-top-tablet.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-top-tablet:focus:not(.is-loading)::after,
  .is-tooltip-top-tablet:hover:not(.is-loading)::after {
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent !important;
  }
  .is-tooltip-top-tablet.is-tooltip-active::before,
  .is-tooltip-top-tablet:focus::before,
  .is-tooltip-top-tablet:hover::before {
    -webkit-transform: translate(-50%, -0.5rem) !important;
    transform: translate(-50%, -0.5rem) !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1087px) {
  .is-tooltip-top-tablet-only::before {
    top: auto !important;
    right: auto !important;
    bottom: 100% !important;
    left: 50% !important;
  }
  .is-tooltip-top-tablet-only.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-top-tablet-only:focus:not(.is-loading)::after,
  .is-tooltip-top-tablet-only:hover:not(.is-loading)::after {
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent !important;
  }
  .is-tooltip-top-tablet-only.is-tooltip-active::before,
  .is-tooltip-top-tablet-only:focus::before,
  .is-tooltip-top-tablet-only:hover::before {
    -webkit-transform: translate(-50%, -0.5rem) !important;
    transform: translate(-50%, -0.5rem) !important;
  }
}
@media screen and (max-width: 1087px) {
  .is-tooltip-top-touch::before {
    top: auto !important;
    right: auto !important;
    bottom: 100% !important;
    left: 50% !important;
  }
  .is-tooltip-top-touch.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-top-touch:focus:not(.is-loading)::after,
  .is-tooltip-top-touch:hover:not(.is-loading)::after {
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent !important;
  }
  .is-tooltip-top-touch.is-tooltip-active::before,
  .is-tooltip-top-touch:focus::before,
  .is-tooltip-top-touch:hover::before {
    -webkit-transform: translate(-50%, -0.5rem) !important;
    transform: translate(-50%, -0.5rem) !important;
  }
}
@media screen and (min-width: 1088px) {
  .is-tooltip-top-desktop::before {
    top: auto !important;
    right: auto !important;
    bottom: 100% !important;
    left: 50% !important;
  }
  .is-tooltip-top-desktop.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-top-desktop:focus:not(.is-loading)::after,
  .is-tooltip-top-desktop:hover:not(.is-loading)::after {
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent !important;
  }
  .is-tooltip-top-desktop.is-tooltip-active::before,
  .is-tooltip-top-desktop:focus::before,
  .is-tooltip-top-desktop:hover::before {
    -webkit-transform: translate(-50%, -0.5rem) !important;
    transform: translate(-50%, -0.5rem) !important;
  }
}
@media screen and (min-width: 1088px) and (max-width: 1279px) {
  .is-tooltip-top-desktop-only::before {
    top: auto !important;
    right: auto !important;
    bottom: 100% !important;
    left: 50% !important;
  }
  .is-tooltip-top-desktop-only.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-top-desktop-only:focus:not(.is-loading)::after,
  .is-tooltip-top-desktop-only:hover:not(.is-loading)::after {
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent !important;
  }
  .is-tooltip-top-desktop-only.is-tooltip-active::before,
  .is-tooltip-top-desktop-only:focus::before,
  .is-tooltip-top-desktop-only:hover::before {
    -webkit-transform: translate(-50%, -0.5rem) !important;
    transform: translate(-50%, -0.5rem) !important;
  }
}
@media screen and (max-width: 1279px) {
  .is-tooltip-top-until-widescreen::before {
    top: auto !important;
    right: auto !important;
    bottom: 100% !important;
    left: 50% !important;
  }
  .is-tooltip-top-until-widescreen.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-top-until-widescreen:focus:not(.is-loading)::after,
  .is-tooltip-top-until-widescreen:hover:not(.is-loading)::after {
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent !important;
  }
  .is-tooltip-top-until-widescreen.is-tooltip-active::before,
  .is-tooltip-top-until-widescreen:focus::before,
  .is-tooltip-top-until-widescreen:hover::before {
    -webkit-transform: translate(-50%, -0.5rem) !important;
    transform: translate(-50%, -0.5rem) !important;
  }
}
@media screen and (min-width: 1280px) {
  .is-tooltip-top-widescreen::before {
    top: auto !important;
    right: auto !important;
    bottom: 100% !important;
    left: 50% !important;
  }
  .is-tooltip-top-widescreen.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-top-widescreen:focus:not(.is-loading)::after,
  .is-tooltip-top-widescreen:hover:not(.is-loading)::after {
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent !important;
  }
  .is-tooltip-top-widescreen.is-tooltip-active::before,
  .is-tooltip-top-widescreen:focus::before,
  .is-tooltip-top-widescreen:hover::before {
    -webkit-transform: translate(-50%, -0.5rem) !important;
    transform: translate(-50%, -0.5rem) !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1471px) {
  .is-tooltip-top-widescreen-only::before {
    top: auto !important;
    right: auto !important;
    bottom: 100% !important;
    left: 50% !important;
  }
  .is-tooltip-top-widescreen-only.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-top-widescreen-only:focus:not(.is-loading)::after,
  .is-tooltip-top-widescreen-only:hover:not(.is-loading)::after {
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent !important;
  }
  .is-tooltip-top-widescreen-only.is-tooltip-active::before,
  .is-tooltip-top-widescreen-only:focus::before,
  .is-tooltip-top-widescreen-only:hover::before {
    -webkit-transform: translate(-50%, -0.5rem) !important;
    transform: translate(-50%, -0.5rem) !important;
  }
}
@media screen and (max-width: 1471px) {
  .is-tooltip-top-until-fullhd::before {
    top: auto !important;
    right: auto !important;
    bottom: 100% !important;
    left: 50% !important;
  }
  .is-tooltip-top-until-fullhd.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-top-until-fullhd:focus:not(.is-loading)::after,
  .is-tooltip-top-until-fullhd:hover:not(.is-loading)::after {
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent !important;
  }
  .is-tooltip-top-until-fullhd.is-tooltip-active::before,
  .is-tooltip-top-until-fullhd:focus::before,
  .is-tooltip-top-until-fullhd:hover::before {
    -webkit-transform: translate(-50%, -0.5rem) !important;
    transform: translate(-50%, -0.5rem) !important;
  }
}
@media screen and (min-width: 1472px) {
  .is-tooltip-top-fullhd::before {
    top: auto !important;
    right: auto !important;
    bottom: 100% !important;
    left: 50% !important;
  }
  .is-tooltip-top-fullhd.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-top-fullhd:focus:not(.is-loading)::after,
  .is-tooltip-top-fullhd:hover:not(.is-loading)::after {
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent !important;
  }
  .is-tooltip-top-fullhd.is-tooltip-active::before,
  .is-tooltip-top-fullhd:focus::before,
  .is-tooltip-top-fullhd:hover::before {
    -webkit-transform: translate(-50%, -0.5rem) !important;
    transform: translate(-50%, -0.5rem) !important;
  }
}
@media screen and (max-width: 768px) {
  .is-tooltip-right-mobile::before {
    top: auto !important;
    right: auto !important;
    bottom: 50% !important;
    left: 100% !important;
    -webkit-transform: translate(-1rem, 50%) !important;
    transform: translate(-1rem, 50%) !important;
  }
  .is-tooltip-right-mobile.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-right-mobile:focus:not(.is-loading)::after,
  .is-tooltip-right-mobile:hover:not(.is-loading)::after {
    top: 50% !important;
    right: auto !important;
    left: 100% !important;
    border-color: transparent rgba(74, 74, 74, 0.9) transparent transparent !important;
  }
  .is-tooltip-right-mobile.is-tooltip-active::before,
  .is-tooltip-right-mobile:focus::before,
  .is-tooltip-right-mobile:hover::before {
    -webkit-transform: translate(0.5rem, 50%) !important;
    transform: translate(0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 769px), print {
  .is-tooltip-right-tablet::before {
    top: auto !important;
    right: auto !important;
    bottom: 50% !important;
    left: 100% !important;
    -webkit-transform: translate(-1rem, 50%) !important;
    transform: translate(-1rem, 50%) !important;
  }
  .is-tooltip-right-tablet.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-right-tablet:focus:not(.is-loading)::after,
  .is-tooltip-right-tablet:hover:not(.is-loading)::after {
    top: 50% !important;
    right: auto !important;
    left: 100% !important;
    border-color: transparent rgba(74, 74, 74, 0.9) transparent transparent !important;
  }
  .is-tooltip-right-tablet.is-tooltip-active::before,
  .is-tooltip-right-tablet:focus::before,
  .is-tooltip-right-tablet:hover::before {
    -webkit-transform: translate(0.5rem, 50%) !important;
    transform: translate(0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1087px) {
  .is-tooltip-right-tablet-only::before {
    top: auto !important;
    right: auto !important;
    bottom: 50% !important;
    left: 100% !important;
    -webkit-transform: translate(-1rem, 50%) !important;
    transform: translate(-1rem, 50%) !important;
  }
  .is-tooltip-right-tablet-only.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-right-tablet-only:focus:not(.is-loading)::after,
  .is-tooltip-right-tablet-only:hover:not(.is-loading)::after {
    top: 50% !important;
    right: auto !important;
    left: 100% !important;
    border-color: transparent rgba(74, 74, 74, 0.9) transparent transparent !important;
  }
  .is-tooltip-right-tablet-only.is-tooltip-active::before,
  .is-tooltip-right-tablet-only:focus::before,
  .is-tooltip-right-tablet-only:hover::before {
    -webkit-transform: translate(0.5rem, 50%) !important;
    transform: translate(0.5rem, 50%) !important;
  }
}
@media screen and (max-width: 1087px) {
  .is-tooltip-right-touch::before {
    top: auto !important;
    right: auto !important;
    bottom: 50% !important;
    left: 100% !important;
    -webkit-transform: translate(-1rem, 50%) !important;
    transform: translate(-1rem, 50%) !important;
  }
  .is-tooltip-right-touch.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-right-touch:focus:not(.is-loading)::after,
  .is-tooltip-right-touch:hover:not(.is-loading)::after {
    top: 50% !important;
    right: auto !important;
    left: 100% !important;
    border-color: transparent rgba(74, 74, 74, 0.9) transparent transparent !important;
  }
  .is-tooltip-right-touch.is-tooltip-active::before,
  .is-tooltip-right-touch:focus::before,
  .is-tooltip-right-touch:hover::before {
    -webkit-transform: translate(0.5rem, 50%) !important;
    transform: translate(0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 1088px) {
  .is-tooltip-right-desktop::before {
    top: auto !important;
    right: auto !important;
    bottom: 50% !important;
    left: 100% !important;
    -webkit-transform: translate(-1rem, 50%) !important;
    transform: translate(-1rem, 50%) !important;
  }
  .is-tooltip-right-desktop.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-right-desktop:focus:not(.is-loading)::after,
  .is-tooltip-right-desktop:hover:not(.is-loading)::after {
    top: 50% !important;
    right: auto !important;
    left: 100% !important;
    border-color: transparent rgba(74, 74, 74, 0.9) transparent transparent !important;
  }
  .is-tooltip-right-desktop.is-tooltip-active::before,
  .is-tooltip-right-desktop:focus::before,
  .is-tooltip-right-desktop:hover::before {
    -webkit-transform: translate(0.5rem, 50%) !important;
    transform: translate(0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 1088px) and (max-width: 1279px) {
  .is-tooltip-right-desktop-only::before {
    top: auto !important;
    right: auto !important;
    bottom: 50% !important;
    left: 100% !important;
    -webkit-transform: translate(-1rem, 50%) !important;
    transform: translate(-1rem, 50%) !important;
  }
  .is-tooltip-right-desktop-only.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-right-desktop-only:focus:not(.is-loading)::after,
  .is-tooltip-right-desktop-only:hover:not(.is-loading)::after {
    top: 50% !important;
    right: auto !important;
    left: 100% !important;
    border-color: transparent rgba(74, 74, 74, 0.9) transparent transparent !important;
  }
  .is-tooltip-right-desktop-only.is-tooltip-active::before,
  .is-tooltip-right-desktop-only:focus::before,
  .is-tooltip-right-desktop-only:hover::before {
    -webkit-transform: translate(0.5rem, 50%) !important;
    transform: translate(0.5rem, 50%) !important;
  }
}
@media screen and (max-width: 1279px) {
  .is-tooltip-right-until-widescreen::before {
    top: auto !important;
    right: auto !important;
    bottom: 50% !important;
    left: 100% !important;
    -webkit-transform: translate(-1rem, 50%) !important;
    transform: translate(-1rem, 50%) !important;
  }
  .is-tooltip-right-until-widescreen.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-right-until-widescreen:focus:not(.is-loading)::after,
  .is-tooltip-right-until-widescreen:hover:not(.is-loading)::after {
    top: 50% !important;
    right: auto !important;
    left: 100% !important;
    border-color: transparent rgba(74, 74, 74, 0.9) transparent transparent !important;
  }
  .is-tooltip-right-until-widescreen.is-tooltip-active::before,
  .is-tooltip-right-until-widescreen:focus::before,
  .is-tooltip-right-until-widescreen:hover::before {
    -webkit-transform: translate(0.5rem, 50%) !important;
    transform: translate(0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 1280px) {
  .is-tooltip-right-widescreen::before {
    top: auto !important;
    right: auto !important;
    bottom: 50% !important;
    left: 100% !important;
    -webkit-transform: translate(-1rem, 50%) !important;
    transform: translate(-1rem, 50%) !important;
  }
  .is-tooltip-right-widescreen.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-right-widescreen:focus:not(.is-loading)::after,
  .is-tooltip-right-widescreen:hover:not(.is-loading)::after {
    top: 50% !important;
    right: auto !important;
    left: 100% !important;
    border-color: transparent rgba(74, 74, 74, 0.9) transparent transparent !important;
  }
  .is-tooltip-right-widescreen.is-tooltip-active::before,
  .is-tooltip-right-widescreen:focus::before,
  .is-tooltip-right-widescreen:hover::before {
    -webkit-transform: translate(0.5rem, 50%) !important;
    transform: translate(0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1471px) {
  .is-tooltip-right-widescreen-only::before {
    top: auto !important;
    right: auto !important;
    bottom: 50% !important;
    left: 100% !important;
    -webkit-transform: translate(-1rem, 50%) !important;
    transform: translate(-1rem, 50%) !important;
  }
  .is-tooltip-right-widescreen-only.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-right-widescreen-only:focus:not(.is-loading)::after,
  .is-tooltip-right-widescreen-only:hover:not(.is-loading)::after {
    top: 50% !important;
    right: auto !important;
    left: 100% !important;
    border-color: transparent rgba(74, 74, 74, 0.9) transparent transparent !important;
  }
  .is-tooltip-right-widescreen-only.is-tooltip-active::before,
  .is-tooltip-right-widescreen-only:focus::before,
  .is-tooltip-right-widescreen-only:hover::before {
    -webkit-transform: translate(0.5rem, 50%) !important;
    transform: translate(0.5rem, 50%) !important;
  }
}
@media screen and (max-width: 1471px) {
  .is-tooltip-right-until-fullhd::before {
    top: auto !important;
    right: auto !important;
    bottom: 50% !important;
    left: 100% !important;
    -webkit-transform: translate(-1rem, 50%) !important;
    transform: translate(-1rem, 50%) !important;
  }
  .is-tooltip-right-until-fullhd.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-right-until-fullhd:focus:not(.is-loading)::after,
  .is-tooltip-right-until-fullhd:hover:not(.is-loading)::after {
    top: 50% !important;
    right: auto !important;
    left: 100% !important;
    border-color: transparent rgba(74, 74, 74, 0.9) transparent transparent !important;
  }
  .is-tooltip-right-until-fullhd.is-tooltip-active::before,
  .is-tooltip-right-until-fullhd:focus::before,
  .is-tooltip-right-until-fullhd:hover::before {
    -webkit-transform: translate(0.5rem, 50%) !important;
    transform: translate(0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 1472px) {
  .is-tooltip-right-fullhd::before {
    top: auto !important;
    right: auto !important;
    bottom: 50% !important;
    left: 100% !important;
    -webkit-transform: translate(-1rem, 50%) !important;
    transform: translate(-1rem, 50%) !important;
  }
  .is-tooltip-right-fullhd.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-right-fullhd:focus:not(.is-loading)::after,
  .is-tooltip-right-fullhd:hover:not(.is-loading)::after {
    top: 50% !important;
    right: auto !important;
    left: 100% !important;
    border-color: transparent rgba(74, 74, 74, 0.9) transparent transparent !important;
  }
  .is-tooltip-right-fullhd.is-tooltip-active::before,
  .is-tooltip-right-fullhd:focus::before,
  .is-tooltip-right-fullhd:hover::before {
    -webkit-transform: translate(0.5rem, 50%) !important;
    transform: translate(0.5rem, 50%) !important;
  }
}
@media screen and (max-width: 768px) {
  .is-tooltip-bottom-mobile::before {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -1rem) !important;
    transform: translate(-50%, -1rem) !important;
  }
  .is-tooltip-bottom-mobile.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-bottom-mobile:focus:not(.is-loading)::after,
  .is-tooltip-bottom-mobile:hover:not(.is-loading)::after {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent !important;
  }
  .is-tooltip-bottom-mobile.is-tooltip-active::before,
  .is-tooltip-bottom-mobile:focus::before,
  .is-tooltip-bottom-mobile:hover::before {
    -webkit-transform: translate(-50%, 0.5rem) !important;
    transform: translate(-50%, 0.5rem) !important;
  }
}
@media screen and (min-width: 769px), print {
  .is-tooltip-bottom-tablet::before {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -1rem) !important;
    transform: translate(-50%, -1rem) !important;
  }
  .is-tooltip-bottom-tablet.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-bottom-tablet:focus:not(.is-loading)::after,
  .is-tooltip-bottom-tablet:hover:not(.is-loading)::after {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent !important;
  }
  .is-tooltip-bottom-tablet.is-tooltip-active::before,
  .is-tooltip-bottom-tablet:focus::before,
  .is-tooltip-bottom-tablet:hover::before {
    -webkit-transform: translate(-50%, 0.5rem) !important;
    transform: translate(-50%, 0.5rem) !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1087px) {
  .is-tooltip-bottom-tablet-only::before {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -1rem) !important;
    transform: translate(-50%, -1rem) !important;
  }
  .is-tooltip-bottom-tablet-only.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-bottom-tablet-only:focus:not(.is-loading)::after,
  .is-tooltip-bottom-tablet-only:hover:not(.is-loading)::after {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent !important;
  }
  .is-tooltip-bottom-tablet-only.is-tooltip-active::before,
  .is-tooltip-bottom-tablet-only:focus::before,
  .is-tooltip-bottom-tablet-only:hover::before {
    -webkit-transform: translate(-50%, 0.5rem) !important;
    transform: translate(-50%, 0.5rem) !important;
  }
}
@media screen and (max-width: 1087px) {
  .is-tooltip-bottom-touch::before {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -1rem) !important;
    transform: translate(-50%, -1rem) !important;
  }
  .is-tooltip-bottom-touch.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-bottom-touch:focus:not(.is-loading)::after,
  .is-tooltip-bottom-touch:hover:not(.is-loading)::after {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent !important;
  }
  .is-tooltip-bottom-touch.is-tooltip-active::before,
  .is-tooltip-bottom-touch:focus::before,
  .is-tooltip-bottom-touch:hover::before {
    -webkit-transform: translate(-50%, 0.5rem) !important;
    transform: translate(-50%, 0.5rem) !important;
  }
}
@media screen and (min-width: 1088px) {
  .is-tooltip-bottom-desktop::before {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -1rem) !important;
    transform: translate(-50%, -1rem) !important;
  }
  .is-tooltip-bottom-desktop.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-bottom-desktop:focus:not(.is-loading)::after,
  .is-tooltip-bottom-desktop:hover:not(.is-loading)::after {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent !important;
  }
  .is-tooltip-bottom-desktop.is-tooltip-active::before,
  .is-tooltip-bottom-desktop:focus::before,
  .is-tooltip-bottom-desktop:hover::before {
    -webkit-transform: translate(-50%, 0.5rem) !important;
    transform: translate(-50%, 0.5rem) !important;
  }
}
@media screen and (min-width: 1088px) and (max-width: 1279px) {
  .is-tooltip-bottom-desktop-only::before {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -1rem) !important;
    transform: translate(-50%, -1rem) !important;
  }
  .is-tooltip-bottom-desktop-only.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-bottom-desktop-only:focus:not(.is-loading)::after,
  .is-tooltip-bottom-desktop-only:hover:not(.is-loading)::after {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent !important;
  }
  .is-tooltip-bottom-desktop-only.is-tooltip-active::before,
  .is-tooltip-bottom-desktop-only:focus::before,
  .is-tooltip-bottom-desktop-only:hover::before {
    -webkit-transform: translate(-50%, 0.5rem) !important;
    transform: translate(-50%, 0.5rem) !important;
  }
}
@media screen and (max-width: 1279px) {
  .is-tooltip-bottom-until-widescreen::before {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -1rem) !important;
    transform: translate(-50%, -1rem) !important;
  }
  .is-tooltip-bottom-until-widescreen.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-bottom-until-widescreen:focus:not(.is-loading)::after,
  .is-tooltip-bottom-until-widescreen:hover:not(.is-loading)::after {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent !important;
  }
  .is-tooltip-bottom-until-widescreen.is-tooltip-active::before,
  .is-tooltip-bottom-until-widescreen:focus::before,
  .is-tooltip-bottom-until-widescreen:hover::before {
    -webkit-transform: translate(-50%, 0.5rem) !important;
    transform: translate(-50%, 0.5rem) !important;
  }
}
@media screen and (min-width: 1280px) {
  .is-tooltip-bottom-widescreen::before {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -1rem) !important;
    transform: translate(-50%, -1rem) !important;
  }
  .is-tooltip-bottom-widescreen.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-bottom-widescreen:focus:not(.is-loading)::after,
  .is-tooltip-bottom-widescreen:hover:not(.is-loading)::after {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent !important;
  }
  .is-tooltip-bottom-widescreen.is-tooltip-active::before,
  .is-tooltip-bottom-widescreen:focus::before,
  .is-tooltip-bottom-widescreen:hover::before {
    -webkit-transform: translate(-50%, 0.5rem) !important;
    transform: translate(-50%, 0.5rem) !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1471px) {
  .is-tooltip-bottom-widescreen-only::before {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -1rem) !important;
    transform: translate(-50%, -1rem) !important;
  }
  .is-tooltip-bottom-widescreen-only.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-bottom-widescreen-only:focus:not(.is-loading)::after,
  .is-tooltip-bottom-widescreen-only:hover:not(.is-loading)::after {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent !important;
  }
  .is-tooltip-bottom-widescreen-only.is-tooltip-active::before,
  .is-tooltip-bottom-widescreen-only:focus::before,
  .is-tooltip-bottom-widescreen-only:hover::before {
    -webkit-transform: translate(-50%, 0.5rem) !important;
    transform: translate(-50%, 0.5rem) !important;
  }
}
@media screen and (max-width: 1471px) {
  .is-tooltip-bottom-until-fullhd::before {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -1rem) !important;
    transform: translate(-50%, -1rem) !important;
  }
  .is-tooltip-bottom-until-fullhd.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-bottom-until-fullhd:focus:not(.is-loading)::after,
  .is-tooltip-bottom-until-fullhd:hover:not(.is-loading)::after {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent !important;
  }
  .is-tooltip-bottom-until-fullhd.is-tooltip-active::before,
  .is-tooltip-bottom-until-fullhd:focus::before,
  .is-tooltip-bottom-until-fullhd:hover::before {
    -webkit-transform: translate(-50%, 0.5rem) !important;
    transform: translate(-50%, 0.5rem) !important;
  }
}
@media screen and (min-width: 1472px) {
  .is-tooltip-bottom-fullhd::before {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -1rem) !important;
    transform: translate(-50%, -1rem) !important;
  }
  .is-tooltip-bottom-fullhd.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-bottom-fullhd:focus:not(.is-loading)::after,
  .is-tooltip-bottom-fullhd:hover:not(.is-loading)::after {
    top: 100% !important;
    right: auto !important;
    bottom: auto !important;
    left: 50% !important;
    border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent !important;
  }
  .is-tooltip-bottom-fullhd.is-tooltip-active::before,
  .is-tooltip-bottom-fullhd:focus::before,
  .is-tooltip-bottom-fullhd:hover::before {
    -webkit-transform: translate(-50%, 0.5rem) !important;
    transform: translate(-50%, 0.5rem) !important;
  }
}
@media screen and (max-width: 768px) {
  .is-tooltip-left-mobile::before {
    top: auto !important;
    right: 100% !important;
    bottom: 50% !important;
    left: auto !important;
    -webkit-transform: translate(1rem, 50%) !important;
    transform: translate(1rem, 50%) !important;
  }
  .is-tooltip-left-mobile.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-left-mobile:focus:not(.is-loading)::after,
  .is-tooltip-left-mobile:hover:not(.is-loading)::after {
    top: 50% !important;
    right: calc(100% - 0.5rem) !important;
    bottom: auto !important;
    left: auto !important;
    border-color: transparent transparent transparent rgba(74, 74, 74, 0.9) !important;
  }
  .is-tooltip-left-mobile.is-tooltip-active::before,
  .is-tooltip-left-mobile:focus::before,
  .is-tooltip-left-mobile:hover::before {
    -webkit-transform: translate(-0.5rem, 50%) !important;
    transform: translate(-0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 769px), print {
  .is-tooltip-left-tablet::before {
    top: auto !important;
    right: 100% !important;
    bottom: 50% !important;
    left: auto !important;
    -webkit-transform: translate(1rem, 50%) !important;
    transform: translate(1rem, 50%) !important;
  }
  .is-tooltip-left-tablet.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-left-tablet:focus:not(.is-loading)::after,
  .is-tooltip-left-tablet:hover:not(.is-loading)::after {
    top: 50% !important;
    right: calc(100% - 0.5rem) !important;
    bottom: auto !important;
    left: auto !important;
    border-color: transparent transparent transparent rgba(74, 74, 74, 0.9) !important;
  }
  .is-tooltip-left-tablet.is-tooltip-active::before,
  .is-tooltip-left-tablet:focus::before,
  .is-tooltip-left-tablet:hover::before {
    -webkit-transform: translate(-0.5rem, 50%) !important;
    transform: translate(-0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1087px) {
  .is-tooltip-left-tablet-only::before {
    top: auto !important;
    right: 100% !important;
    bottom: 50% !important;
    left: auto !important;
    -webkit-transform: translate(1rem, 50%) !important;
    transform: translate(1rem, 50%) !important;
  }
  .is-tooltip-left-tablet-only.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-left-tablet-only:focus:not(.is-loading)::after,
  .is-tooltip-left-tablet-only:hover:not(.is-loading)::after {
    top: 50% !important;
    right: calc(100% - 0.5rem) !important;
    bottom: auto !important;
    left: auto !important;
    border-color: transparent transparent transparent rgba(74, 74, 74, 0.9) !important;
  }
  .is-tooltip-left-tablet-only.is-tooltip-active::before,
  .is-tooltip-left-tablet-only:focus::before,
  .is-tooltip-left-tablet-only:hover::before {
    -webkit-transform: translate(-0.5rem, 50%) !important;
    transform: translate(-0.5rem, 50%) !important;
  }
}
@media screen and (max-width: 1087px) {
  .is-tooltip-left-touch::before {
    top: auto !important;
    right: 100% !important;
    bottom: 50% !important;
    left: auto !important;
    -webkit-transform: translate(1rem, 50%) !important;
    transform: translate(1rem, 50%) !important;
  }
  .is-tooltip-left-touch.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-left-touch:focus:not(.is-loading)::after,
  .is-tooltip-left-touch:hover:not(.is-loading)::after {
    top: 50% !important;
    right: calc(100% - 0.5rem) !important;
    bottom: auto !important;
    left: auto !important;
    border-color: transparent transparent transparent rgba(74, 74, 74, 0.9) !important;
  }
  .is-tooltip-left-touch.is-tooltip-active::before,
  .is-tooltip-left-touch:focus::before,
  .is-tooltip-left-touch:hover::before {
    -webkit-transform: translate(-0.5rem, 50%) !important;
    transform: translate(-0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 1088px) {
  .is-tooltip-left-desktop::before {
    top: auto !important;
    right: 100% !important;
    bottom: 50% !important;
    left: auto !important;
    -webkit-transform: translate(1rem, 50%) !important;
    transform: translate(1rem, 50%) !important;
  }
  .is-tooltip-left-desktop.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-left-desktop:focus:not(.is-loading)::after,
  .is-tooltip-left-desktop:hover:not(.is-loading)::after {
    top: 50% !important;
    right: calc(100% - 0.5rem) !important;
    bottom: auto !important;
    left: auto !important;
    border-color: transparent transparent transparent rgba(74, 74, 74, 0.9) !important;
  }
  .is-tooltip-left-desktop.is-tooltip-active::before,
  .is-tooltip-left-desktop:focus::before,
  .is-tooltip-left-desktop:hover::before {
    -webkit-transform: translate(-0.5rem, 50%) !important;
    transform: translate(-0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 1088px) and (max-width: 1279px) {
  .is-tooltip-left-desktop-only::before {
    top: auto !important;
    right: 100% !important;
    bottom: 50% !important;
    left: auto !important;
    -webkit-transform: translate(1rem, 50%) !important;
    transform: translate(1rem, 50%) !important;
  }
  .is-tooltip-left-desktop-only.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-left-desktop-only:focus:not(.is-loading)::after,
  .is-tooltip-left-desktop-only:hover:not(.is-loading)::after {
    top: 50% !important;
    right: calc(100% - 0.5rem) !important;
    bottom: auto !important;
    left: auto !important;
    border-color: transparent transparent transparent rgba(74, 74, 74, 0.9) !important;
  }
  .is-tooltip-left-desktop-only.is-tooltip-active::before,
  .is-tooltip-left-desktop-only:focus::before,
  .is-tooltip-left-desktop-only:hover::before {
    -webkit-transform: translate(-0.5rem, 50%) !important;
    transform: translate(-0.5rem, 50%) !important;
  }
}
@media screen and (max-width: 1279px) {
  .is-tooltip-left-until-widescreen::before {
    top: auto !important;
    right: 100% !important;
    bottom: 50% !important;
    left: auto !important;
    -webkit-transform: translate(1rem, 50%) !important;
    transform: translate(1rem, 50%) !important;
  }
  .is-tooltip-left-until-widescreen.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-left-until-widescreen:focus:not(.is-loading)::after,
  .is-tooltip-left-until-widescreen:hover:not(.is-loading)::after {
    top: 50% !important;
    right: calc(100% - 0.5rem) !important;
    bottom: auto !important;
    left: auto !important;
    border-color: transparent transparent transparent rgba(74, 74, 74, 0.9) !important;
  }
  .is-tooltip-left-until-widescreen.is-tooltip-active::before,
  .is-tooltip-left-until-widescreen:focus::before,
  .is-tooltip-left-until-widescreen:hover::before {
    -webkit-transform: translate(-0.5rem, 50%) !important;
    transform: translate(-0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 1280px) {
  .is-tooltip-left-widescreen::before {
    top: auto !important;
    right: 100% !important;
    bottom: 50% !important;
    left: auto !important;
    -webkit-transform: translate(1rem, 50%) !important;
    transform: translate(1rem, 50%) !important;
  }
  .is-tooltip-left-widescreen.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-left-widescreen:focus:not(.is-loading)::after,
  .is-tooltip-left-widescreen:hover:not(.is-loading)::after {
    top: 50% !important;
    right: calc(100% - 0.5rem) !important;
    bottom: auto !important;
    left: auto !important;
    border-color: transparent transparent transparent rgba(74, 74, 74, 0.9) !important;
  }
  .is-tooltip-left-widescreen.is-tooltip-active::before,
  .is-tooltip-left-widescreen:focus::before,
  .is-tooltip-left-widescreen:hover::before {
    -webkit-transform: translate(-0.5rem, 50%) !important;
    transform: translate(-0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1471px) {
  .is-tooltip-left-widescreen-only::before {
    top: auto !important;
    right: 100% !important;
    bottom: 50% !important;
    left: auto !important;
    -webkit-transform: translate(1rem, 50%) !important;
    transform: translate(1rem, 50%) !important;
  }
  .is-tooltip-left-widescreen-only.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-left-widescreen-only:focus:not(.is-loading)::after,
  .is-tooltip-left-widescreen-only:hover:not(.is-loading)::after {
    top: 50% !important;
    right: calc(100% - 0.5rem) !important;
    bottom: auto !important;
    left: auto !important;
    border-color: transparent transparent transparent rgba(74, 74, 74, 0.9) !important;
  }
  .is-tooltip-left-widescreen-only.is-tooltip-active::before,
  .is-tooltip-left-widescreen-only:focus::before,
  .is-tooltip-left-widescreen-only:hover::before {
    -webkit-transform: translate(-0.5rem, 50%) !important;
    transform: translate(-0.5rem, 50%) !important;
  }
}
@media screen and (max-width: 1471px) {
  .is-tooltip-left-until-fullhd::before {
    top: auto !important;
    right: 100% !important;
    bottom: 50% !important;
    left: auto !important;
    -webkit-transform: translate(1rem, 50%) !important;
    transform: translate(1rem, 50%) !important;
  }
  .is-tooltip-left-until-fullhd.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-left-until-fullhd:focus:not(.is-loading)::after,
  .is-tooltip-left-until-fullhd:hover:not(.is-loading)::after {
    top: 50% !important;
    right: calc(100% - 0.5rem) !important;
    bottom: auto !important;
    left: auto !important;
    border-color: transparent transparent transparent rgba(74, 74, 74, 0.9) !important;
  }
  .is-tooltip-left-until-fullhd.is-tooltip-active::before,
  .is-tooltip-left-until-fullhd:focus::before,
  .is-tooltip-left-until-fullhd:hover::before {
    -webkit-transform: translate(-0.5rem, 50%) !important;
    transform: translate(-0.5rem, 50%) !important;
  }
}
@media screen and (min-width: 1472px) {
  .is-tooltip-left-fullhd::before {
    top: auto !important;
    right: 100% !important;
    bottom: 50% !important;
    left: auto !important;
    -webkit-transform: translate(1rem, 50%) !important;
    transform: translate(1rem, 50%) !important;
  }
  .is-tooltip-left-fullhd.is-tooltip-active:not(.is-loading)::after,
  .is-tooltip-left-fullhd:focus:not(.is-loading)::after,
  .is-tooltip-left-fullhd:hover:not(.is-loading)::after {
    top: 50% !important;
    right: calc(100% - 0.5rem) !important;
    bottom: auto !important;
    left: auto !important;
    border-color: transparent transparent transparent rgba(74, 74, 74, 0.9) !important;
  }
  .is-tooltip-left-fullhd.is-tooltip-active::before,
  .is-tooltip-left-fullhd:focus::before,
  .is-tooltip-left-fullhd:hover::before {
    -webkit-transform: translate(-0.5rem, 50%) !important;
    transform: translate(-0.5rem, 50%) !important;
  }
}
