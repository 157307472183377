html,
body {
  height: 100%;
  margin: 0;
  font-family: "Barlow";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root > .container {
  padding: 15px;
}
.footer {
  padding-bottom: 3rem;
}

.is-danger > .message-body:empty,
.is-success > .message-body:empty {
  display: none;
}

li {
  overflow-wrap: break-word;
}

code {
  padding: 0.15em 0.35em 0.15em;
  border-radius: 3px;
}

a.button {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.sponsor {
  width: 48%;
  margin: 5px;
  padding-top: 10vh;
  padding-bottom: 10vh;
  white-space: pre-line;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  transition: all 0.2s ease-out, filter 0.2s ease-in;
}

.sponsor:hover {
  color: white;
  background-color: #209cee;
}

a.sponsor {
  display: inline-flex;
}

a > i {
  margin-right: 10px;
  transform: scale(1.25);
}
.hidden {
  display: none;
  opacity: 0;
}
.shown {
  display: block;
  opacity: 1;
}

.bluejeans {
  width: 50%;
  margin: auto;
}

.single-line-button {
  margin: 10px 0;
  line-height: 2.25em; /* Same height as button */
}
.single-line-button > button {
  margin-left: 15px;
}

.is-grouped.is-multiline {
  flex-wrap: wrap;
  margin-top: -12px;
}
.is-grouped.is-multiline > * {
  margin-top: 12px;
}

*[hidden] {
  display: none !important;
}

.tabs.is-toggle li.is-active a {
  background-color: #4a4a4a;
  border-color: #212121;
}

#settings h1,
#settings h2,
#settings h5 {
  margin-bottom: 0;
}

.table td {
  vertical-align: middle;
}

.modal-card {
  width: calc(90vw - 40px);
  height: calc(100vh - 40px);
}
.modal-title {
  flex-grow: 1;
  flex-shrink: 0;
}
.modal-card-foot > .buttons {
  width: 100%;
}
.spacer {
  flex-grow: 1;
}
embed,
iframe {
  width: 100%;
  height: 90%;
}

.side-by-side-flex {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.side-by-side-flex > :first-child {
  margin-right: 5px;
}
.tags-column {
  max-width: 160px;
  word-wrap: break-word;
}
.modal-card-body {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 0;
}
#detail-notes > li:not(.no-prefix)::before {
  content: "– ";
}
#detail-notes > li > .button {
  margin: 1px;
}
.dropdown-menu.far-right {
  right: 0;
  left: auto;
}
#search-control,
.progress:not(:last-child) {
  margin-bottom: 0.75rem;
}
.message.toast {
  position: fixed;
  right: 0;
  bottom: -100px;
  left: 0;
  z-index: 1000;
  margin: 0;
  text-align: center;
  border-radius: 0;
  transition: bottom 0.25s ease-in-out;
}
.message.toast.is-active {
  bottom: 0;
}
.message.toast > .message-body {
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 4px 0 0 0;
  border-radius: 0;
}
.message.toast > .message-body > .button {
  margin-left: 0.75rem;
}
.message.toast > .message-body > .delete {
  position: absolute;
  top: 30px;
  right: 30px;
}
a.dropdown-item {
  padding-right: 1rem;
}
.dropdown-item > label {
  display: flex;
  align-items: center;
}
.dropdown-item > label > input {
  margin-right: 0.5rem;
}

.name-link {
  text-decoration: none;
  cursor: pointer;
}
.name-link:hover {
  text-decoration: underline;
}

.modal-mobile-foot {
  display: none;
}
@media only screen and (max-width: 768px) {
  .modal-card {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    max-height: 100vh;
  }
  .modal-card-body {
    padding: 10px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  #detail-close {
    display: none;
  }
  .modal-card-foot {
    display: none;
  }
  .modal-mobile-foot {
    display: block;
  }
  hr {
    margin: 0.5rem 0;
  }
  .button.is-fullwidth {
    margin: 0.5rem 0;
  }
  embed,
  iframe {
    flex-shrink: 0;
  }

  /* Hide Major, Links, Tags columns on mobile */
  tr td:nth-child(4),
  th:nth-child(4),
  tr td:nth-child(5),
  th:nth-child(5),
  tr td:nth-child(6),
  th:nth-child(6) {
    display: none;
  }
  .view-action {
    margin-top: 5px;
  }
}

.main_stage_wrapper {
  width: 100%;
  position: relative;
  padding-bottom: 56.45%;
}

.main_stage_wrapper_youtube {
  width: 100%;
  position: relative;
  padding-bottom: 56.45%;
  display: flex;
  flex-direction: row;
}

@media (max-width: 900px) {
  .main_stage_wrapper_youtube {
    flex-direction: column;
  }
}

.main_stage_container {
  margin: 0 auto;
  width: calc(60% + 300px);
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.youtubeStage {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 300px);
  height: 100%;
}

.youtubechat {
  position: absolute;
  top: 0;
  left: calc(100% - 300px);
  width: 300px;
  height: 100%;
}

.dailyStage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main_stage_placeholder {
  background-color: #c4c4c4;
  height: 600px;
  width: 100%;
}

.main_stage_content {
  padding: 20px;
  width: 100%;
}
